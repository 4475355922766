// extracted by mini-css-extract-plugin
export var aboutImgHolder = "About__aboutImgHolder__fh2R0";
export var aboutLayout = "About__aboutLayout__vQMXo";
export var aboutStory = "About__aboutStory__Lwkzf";
export var column = "About__column__UKrrh";
export var column3 = "About__column3__Lfl2i";
export var container = "About__container__q3q2f";
export var containerGutter = "About__containerGutter__lhF6Q";
export var description = "About__description__od1Pu";
export var disclaimer = "About__disclaimer__oTpdZ";
export var divider = "About__divider__jDYkV";
export var feature = "About__feature__vU2J_";
export var features = "About__features__18fH9";
export var iconContainer = "About__iconContainer__u8wwZ";
export var joinProgram = "About__joinProgram__Qniqe";
export var list = "About__list__j0Tt8";
export var marginButtom0 = "About__marginButtom0__MjcoH";
export var marginHorizontal0 = "About__marginHorizontal0__bs_8h";
export var marginTop0 = "About__marginTop0__tpjmm";
export var overview = "About__overview__dncOf";
export var paddingHorizontal0 = "About__paddingHorizontal0__lcrre";
export var partner = "About__partner__lmFUE";
export var row = "About__row___TMI_";
export var slider = "About__slider__hA2G5";
export var sliderContainer = "About__sliderContainer__PkpY3";
export var stroryDetails = "About__stroryDetails__VfC_v";
export var testimonials = "About__testimonials__p1Aon";
export var textContainer = "About__textContainer__TJxbC";