// extracted by mini-css-extract-plugin
export var button = "ProgramBox__button__zUlvA";
export var centerAlign = "ProgramBox__centerAlign__l0Vbs";
export var column = "ProgramBox__column__nfpfG";
export var container = "ProgramBox__container__KCbhi";
export var containerGutter = "ProgramBox__containerGutter__w9UyT";
export var description = "ProgramBox__description__wY6cw";
export var leftAlign = "ProgramBox__leftAlign__HdWLf";
export var marginButtom0 = "ProgramBox__marginButtom0__Tn3sT";
export var marginHorizontal0 = "ProgramBox__marginHorizontal0__nzZey";
export var marginTop0 = "ProgramBox__marginTop0__Z6CIp";
export var paddingHorizontal0 = "ProgramBox__paddingHorizontal0__ohMoY";
export var programContainer = "ProgramBox__programContainer__ldIt_";
export var programIconHolder = "ProgramBox__programIconHolder__pqWHr";
export var rightAlign = "ProgramBox__rightAlign__L8e_7";
export var row = "ProgramBox__row__ztra9";
export var textContainer = "ProgramBox__textContainer__pNv3h";