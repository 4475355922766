// extracted by mini-css-extract-plugin
export var aboutSectionHeadContainer = "AboutSectionHead__aboutSectionHeadContainer__r6L9V";
export var column = "AboutSectionHead__column__gXoXX";
export var container = "AboutSectionHead__container___FOp3";
export var containerGutter = "AboutSectionHead__containerGutter__q8l7j";
export var marginButtom0 = "AboutSectionHead__marginButtom0__JosyR";
export var marginHorizontal0 = "AboutSectionHead__marginHorizontal0__hgnPw";
export var marginTop0 = "AboutSectionHead__marginTop0__tYMeN";
export var paddingHorizontal0 = "AboutSectionHead__paddingHorizontal0__u57h1";
export var row = "AboutSectionHead__row__PsgOp";
export var subtitle = "AboutSectionHead__subtitle__oovJH";